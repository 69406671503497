<template>
  <div v-if="!isLoading" class="section-area board" :class="{'bg-red': redBackground, 'w-100': showAllColumns}">
    <div class="section-header pb-3">
      <h3 class="title" v-if="customTitle">{{customTitle}}</h3>
      <h3 class="title" v-else-if="boardMst">{{boardMst.boardTitle}}</h3>
      <h3 class="title" v-else>Hot Tip</h3>
      <slot v-if="!isMobile" name="subTitle">
        <div>
          <span v-if="boardId == 'hottip-notice'" class="title-sub me-3" v-html="hotTipManagerInfo"></span>
          <span class="title-sub kb-mouse-cursor" @click.stop="moveToBoardList">전체보기</span>
        </div>
      </slot>
    </div>
    <div class="section-body">
      <div class="board-preview-list">
        <template v-if="boardList.length > 0">
          <template v-if="showNotice">
            <ul class="lh-lg">
              <li v-for="(item,idx) in boardList" :key="idx" class="title-area d-flex align-items-center">
                <span class="text me-3">{{timestampToDateFormat(item.regDt, 'yyyy.MM.dd')}}</span>
                <template v-for="badge in item.badges" :key="badge.boardBadgeSn">
                  <div v-if="badge.stt === '00'" class="badge badge-red"><span class="badge-text">{{badge.postBadgeNm}}</span></div>
                </template>
                <span class="kb-mouse-cursor text-truncate" :title="item.postTitle" @click="moveToBoardDtl(item)">{{item.postTitle}}</span>
              </li>
            </ul>
          </template>
          <template v-else-if="showAllColumns">
            <ul class="board-preview-table">
              <li v-for="(item,idx) in boardList" :key="idx" class="kb-table-row" :class="{'opacity-50': item.isRead === 'Y'}">
                <div v-if="boardHeaders.includes('category')" class="kb-table-cell category-area">
                  <span class="text-gold">{{item.cateNm}}</span>
                </div>
                <div class="kb-table-cell title-area">
                  <div class="d-flex align-items-center">
                    <template v-for="badge in item.badges" :key="badge.boardBadgeSn">
                      <div v-if="badge.stt === '00'" class="badge badge-red"><span class="badge-text">{{badge.postBadgeNm}}</span></div>
                    </template>
                    <span v-if="boardHeaders.includes('title')" class="text kb-mouse-cursor" :title="item.postTitle" @click="moveToBoardDtl(item)">{{item.postTitle}}</span>
                    <template v-if="item.stt === '01'">
                      <div class="badge badge-silver ms-2"><span class="badge-text">작성중</span></div>
                    </template>
                    <template v-else>
                      <i v-if="boardMst.newBadgeYn === 'Y' && item.isNew === 'Y'" class="icon-new"></i>
                    </template>
                  </div>
                </div>
                <div v-if="boardHeaders.includes('rgtrId')" class="kb-table-cell meta">
                  <span class="text">{{item.lrnerNm}} |  {{item.deptNm}}</span>
                </div>
                <div v-if="boardHeaders.includes('regDt')" class="kb-table-cell meta">
                  <span class="text">{{timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm')}}</span>
                </div>
                <div v-if="boardHeaders.includes('inqCnt')" class="kb-table-cell icon-info">
                  <span class="text align-text-top"><i class="icon-view" /> {{item.inqCnt}}</span>
                </div>
                <div v-if="boardHeaders.includes('likeCnt')" class="kb-table-cell icon-info">
                  <span class="text align-text-top"><i class="icon-like" :class="{'is-active': item.isLike == 'Y'}" /> {{item.likeCnt}}</span>
                </div>
              </li>
            </ul>
          </template>
          <template v-else>
            <ul class="board-preview-table">
              <li v-for="(item,idx) in boardList" :key="idx" class="kb-table-row" :class="{'opacity-50': item.isRead === 'Y'}">
                <div class="kb-table-cell category-area">
                  <span class="text-gold">{{item.cateNm}}</span>
                </div>
                <div class="kb-table-cell title-area">
                  <div class="d-flex align-items-center">
                    <template v-for="badge in item.badges" :key="badge.boardBadgeSn">
                      <div v-if="badge.stt === '00'" class="badge badge-red"><span class="badge-text">{{badge.postBadgeNm}}</span></div>
                    </template>
                    <span class="text kb-mouse-cursor" :title="item.postTitle" @click="moveToBoardDtl(item)">{{item.postTitle}}</span>
                    <i v-if="item.isNew === 'Y' && item.stt != '01'" class="icon-new"></i>
                  </div>
                </div>
                <div v-if="item.stt == '01'" class="kb-table-cell text-end">
                  <div class="badge badge-silver"><span class="badge-text">작성중</span></div>
                </div>
              </li>
            </ul>
          </template>
        </template>
        <template v-else>
          <p class="text-center text-muted">게시글이 없습니다.</p>
        </template>
      </div>
    </div>
    <slot v-if="isMobile && boardList.length > 0" name="subTitle">
      <div class="section-bottom mt-3">
        <button class="kb-btn kb-btn-wide kb-btn-outline kb-btn-xs" @click="moveToBoardList"><span class="text">전체보기</span></button>
      </div>
    </slot>
  </div>
  <div v-else class="section-area board">
    <LoadingDiv />
  </div>
</template>

<script>
import {computed, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import LoadingDiv from "@/components/common/LoadingDiv.vue";
import {getPostList, goToEdit} from "@/assets/js/modules/board/board-common";
import {
  getHotTipBoardCateList,
  getHotTipBoardMst,
  HotTipBoardBadgeCdDcd,
  hotTipManagerInfo,
} from "@/assets/js/modules/learn/learn-hot-tip";
import {timestampToDateFormat} from "@/assets/js/util";

export default {
  name: 'HotTipBoardPreview',
  components: {LoadingDiv},
  props: {
    boardId: String,
    customTitle: String,
    customBoardList: {type: Array, default: null},
    pageSize: {type: Number, default: 10},
    showNotice: Boolean,
    showAllColumns: Boolean,
    redBackground: Boolean,
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const session = computed(() => store.state.auth.session);
    const isMobile = computed(() => store.state.auth.isMobile);
    const isLoading = ref(true);
    const boardMst = computed(() => getHotTipBoardMst(props.boardId));
    const cateList = computed(() => getHotTipBoardCateList(props.boardId));
    const boardHeaders = computed(() => boardMst.value.boardItmList.split("$"));
    const boardList = ref([]);

    const moveToBoardList = () => {
      if(boardMst.value === null){
        // 우수 Tip 모아보기
        router.push({name: 'HotTipSearch',query:{'postBadgeCdDcd':HotTipBoardBadgeCdDcd.BEST}});
      }else if(boardMst.value.boardId === 'hottip-notice'){
        // Hot Tip 공지사항
        router.push({name:'BoardMst',params:{'boardId':boardMst.value.boardId},query:{'hisback':1}});
      }else{
        let queryParam = {};
        if(route.query.boardCateSns && cateList.value.length > 0){
          queryParam['boardCateSn'] = cateList.value.map(x => x.boardCateSn).find(y => route.query.boardCateSns.map(Number).includes(y));
        }
        if(route.query.isLike == 'Y'){
          queryParam['isLike'] = route.query.isLike;
        }
        if(route.query.postBadgeCdDcd){
          queryParam['postBadgeCdDcd'] = route.query.postBadgeCdDcd;
        }
        if(route.query.type === 'my') {
          queryParam['word'] = session.value.lrnerNm;
        }else if(route.query.word){
          queryParam['word'] = route.query.word;
        }
        router.push({name:'HotTipBoard', params:{'boardId':boardMst.value.boardId}, query: queryParam});
      }
    }

    // 게시글 상세페이지 이동
    const moveToBoardDtl = (item) => {
      if(item.stt == '01'){
        goToEdit(item.boardSecuKey, item.boardId);
      }else{
        router.push({name:'BoardView',params:{'boardId':item.boardId, 'boardSecuKey':item.boardSecuKey}});
      }
    }

    const getHotTipPostList = () => {
      const postListParam = {...route.query};
      postListParam['boardMstSn'] = boardMst.value.boardMstSn;
      postListParam['pageNo'] = 1;
      postListParam['pageSize'] = props.pageSize;
      if(route.query.boardCateSns){
        if(cateList.value.length > 1){
          postListParam['boardCateSn'] = cateList.value.map(x => x.boardCateSn).find(y => route.query.boardCateSns.map(Number).includes(y));
        }else{
          postListParam['boardCateSn'] = 1;
        }
        postListParam['boardCateSns'] = null;
      }
      if(route.query.type && route.query.type === 'my') {
        postListParam['rgtrId'] = session.value.lrnerId;
        postListParam['stt'] = '01';
      }

      getPostList(postListParam, boardList, null, cateList.value, () => {
        isLoading.value = false;
      });
    }

    const getBoardList = () => {
      if(props.customBoardList != null){
        boardList.value = props.customBoardList;
        isLoading.value = !(props.customBoardList.length > 0);
      }else if(props.boardId && boardMst.value){
        getHotTipPostList();
      }
    }

    watch(() => [boardMst.value, props.customBoardList], getBoardList)

    onMounted(getBoardList)


    return {
      isMobile,
      isLoading,
      boardMst,
      boardHeaders,
      boardList,
      hotTipManagerInfo,
      moveToBoardList,
      moveToBoardDtl,
      timestampToDateFormat,
    }
  }
}
</script>